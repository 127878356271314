import styled, { css } from "styled-components";
import { BP, Colors, Rem } from "../../commons/Theme";

export const StyledProductList = styled.div`
    margin: ${Rem(20)} auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    color: ${Colors.black};
    justify-content: flex-start;
    @media (${BP.tablet}) {
      justify-content: space-around;
    }
`;

export const StyledProductListContainer = styled.div`

  @media (${BP.tablet}) {
    width: 90%;
    margin-left:5%;
  }

  ${() => css`
    .simplebar-mask {
      z-index: auto;
    }
  `}
`;
